import 'keen-slider/keen-slider.min.css'
import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { createState, State, useState as HookState } from '@hookstate/core'
import { Props as ImageProps } from 'app/components/Common/Image'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const globalState = createState('')
const wrapState = (s: State<string>) => ({
  get: () => s.value,
  set: (p: any) => s.set(() => p),
})

export const accessGlobalState = () => wrapState(globalState)
export const useGlobalState = () => wrapState(HookState(globalState))

export interface Props {
  thumbnails?: ImageProps[]
}

export const Slider = memo(function Slider({ thumbnails }: Props) {
  if (!thumbnails) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    duration: 2000,
    loop: true,
    move(s) {
      setDetails(s.details())
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
      accessGlobalState().set(s.details().relativeSlide)
    },
  })

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.positions[idx]
    const x = details.widthOrHeight * -position.distance
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  return (
    <Container>
      <Carousel ref={sliderRef}>
        {uniq(thumbnails)
          .slice(0, 3)
          .map((item, index) => (
            <Slide key={index} className="keen-slider__slide">
              <Inner style={positionStyle(index)}>
                <LazyLoadImage effect="blur" {...item} />
              </Inner>
            </Slide>
          ))}
      </Carousel>
      {thumbnails.length > 1 ? (
        <Dots>
          {uniq(thumbnails)
            .slice(0, 3)
            .map((_item, index) => (
              <Dot
                key={index}
                className={currentSlide === index ? 'active' : undefined}
                onClick={() => {
                  slider.moveToSlideRelative(index)
                }}
              />
            ))}
        </Dots>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding-bottom: 72%;
  position: relative;
`

const Carousel = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Dots = styled.div`
  position: absolute;
  top: 50%;
  right: 2.5rem;
  z-index: 2;
  transform: translateY(-50%);

  @media (max-width: 1023px) {
    display: none;
  }
`

const Dot = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight3};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.2;
  margin-top: 1.875rem;
  transition: 0.3s ease-in-out;
  &:first-of-type {
    margin-top: 0;
  }
  &.active {
    opacity: 1;
    pointer-events: none;
  }
`
