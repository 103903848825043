import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { compact, uniqBy } from 'lodash'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'

import { Filter } from './Filter'
import { Props as RoomProps, Room } from './Room'

export interface Props {
  languageCode: string
  rooms: RoomProps[]
}

export const RoomsList = memo(function RoomsList({
  languageCode,
  rooms,
}: Props) {
  if (!rooms) {
    return null
  }

  const categories = uniqBy(
    rooms.map((item) => {
      const filteredRooms = rooms.filter((t) => t.category === item.category)

      const list = filteredRooms.map((e) => e)
      const pax = compact(filteredRooms.map((e) => e.pax))
      const size = compact(filteredRooms.map((e) => e.size))
      const title = item.category

      return {
        list,
        pax: size.length
          ? `${Math.min(...pax)}-${Math.max(...pax)} pax`
          : undefined,
        size: size.length
          ? `${Math.min(...size)}-${Math.max(...size)} ${useVocabularyData(
              'mq',
              languageCode,
            )}`
          : undefined,
        title,
      }
    }),
    'title',
  )

  const containerPosition = useRef<HTMLDivElement>(null)
  const [filtersFixed, setFiltersFixed] = useState(false)
  const [filteredCategory, setFilteredCategory] = useState(null)

  /* const scrollTo = (ref: any) => {
    

    if (ref && ref.current) {
      console.log(ref)
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  } */

  useEffect(() => {
    const onScroll = () => {
      setFiltersFixed(
        window.scrollY - 300 >
          (containerPosition.current ? containerPosition.current.offsetTop : 0),
      )
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const scroll = useCallback((node) => {
    const hash =
      typeof window !== 'undefined'
        ? new URLSearchParams(window.location.search).get('rooms') || undefined
        : undefined

    if (node !== null && node.id === hash) {
      setTimeout(() => {
        window.scrollTo({
          top: node.getBoundingClientRect().top - 230,
        })
      }, 100)
    }
  }, [])

  return (
    <Container ref={containerPosition}>
      {categories.length > 1 ? (
        <Filters
          className={filtersFixed ? 'fixed' : undefined}
          dial={5}
          row
          wrap
        >
          {categories.map((item, index) => {
            const category = item.title
              .replace(/[^a-zA-Z]/g, '')
              .toLocaleLowerCase()

            return category ? (
              <Filter
                active={filteredCategory === category}
                category={category}
                key={index}
                onClick={(e) => {
                  if (filteredCategory === category) {
                    setFilteredCategory(null)
                  } else {
                    setFilteredCategory(e.target.getAttribute('data-category'))
                  }

                  window.scrollTo(
                    0,
                    containerPosition.current
                      ? containerPosition.current.offsetTop
                      : 0,
                  )
                }}
                pax={item.pax}
                size={item.size}
                title={item.title}
              />
            ) : undefined
          })}
        </Filters>
      ) : null}
      <Categories>
        {categories.map((item, index) => {
          const category = item.title
            .replace(/[^a-zA-Z]/g, '')
            .toLocaleLowerCase()

          return (
            <Category
              id={category}
              ref={scroll}
              className={
                filteredCategory === category || filteredCategory === null
                  ? 'visible'
                  : undefined
              }
              key={index}
            >
              <Head dial={4} row wrap>
                <FadeInUp>
                  <Label>{useVocabularyData('rooms', languageCode)}</Label>
                  <Title>{item.title}</Title>
                </FadeInUp>
                <FadeInUp>
                  <Info>
                    <Size>{item.size}</Size>
                    <Pax>{item.pax}</Pax>
                  </Info>
                </FadeInUp>
              </Head>
              {item.list.map((item, index) => (
                <Room key={index} {...item} />
              ))}
            </Category>
          )
        })}
      </Categories>
    </Container>
  )
})

const Container = styled.section`
  padding: 10.25rem 14.444vw 12.5rem 5.833vw;
  position: relative;

  @media (max-width: 1023px) {
    padding: 5.625rem 6.039vw 7.5rem;
  }
`

const Filters = styled(FlexBox)`
  width: 100%;
  padding: 0 5.833vw;
  position: absolute;
  top: 7.5rem;
  left: 0;
  &.fixed {
    animation: filtersInTop 0.3s;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    padding-top: 1.25rem;
    padding-bottom: 0.375rem;
    position: fixed;
    top: 3.75rem;
    z-index: 10;

    > div {
      width: auto;
      &:after {
        display: none;
      }

      div {
        &:last-of-type {
          display: none;
        }
      }
    }
  }

  @keyframes filtersInTop {
    0% {
      top: 0;
    }
    100% {
      top: 3.75rem;
    }
  }

  @media (max-width: 1023px) {
    top: 3.75rem;
  }
`

const Categories = styled.div``

const Category = styled.div`
  display: none;
  margin-top: 11.25rem;
  &.visible {
    display: block;
  }

  @media (max-width: 1023px) {
    margin-top: 5.625rem;
  }
`

const Head = styled(FlexBox)`
  margin-bottom: 3.75rem;

  @media (max-width: 1023px) {
    margin-bottom: -1.875rem;
    div {
      width: 100%;
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  margin-bottom: -1.125rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 8.125rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 9.375rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 4.125rem;
    line-height: 4.125rem;
  }
`

const Info = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.125rem;
  margin-left: 3.75rem;
  padding-left: 2.5rem;
  position: relative;
  text-transform: uppercase;
  &:before {
    content: '';
    width: 0.625rem;
    height: 120%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: -8%;
    left: 0;
  }

  @media (max-width: 1023px) {
    display: flex;
    font-size: 1.125rem;
    line-height: 1.25rem;
    margin: 0.375rem 0 0 0;
    padding-left: 0;
    &:before {
      display: none;
    }
    div {
      display: inline;
      width: auto;
      &:nth-of-type(2) {
        margin-left: 1.125rem;
      }
    }
  }
`

const Size = styled.div``

const Pax = styled.div``
