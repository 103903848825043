import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  image?: ImageProps
  services: ServiceProps[]
  title?: string
}

export const RoomsServices = memo(function RoomsServices({
  image,
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container row wrap stretch tag="section">
      <LeftSide dial={4}>
        {image ? <LazyLoadImage effect="blur" {...image} /> : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
      </LeftSide>
      <RightSide row wrap stretch>
        {services.map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  margin-top: -3.75rem;
`

const LeftSide = styled(FlexBox)`
  width: 34%;
  overflow: hidden;
  padding: 0 5.8333vw;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  > div {
    position: relative;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-top: 7.5rem;
    padding-bottom: 3.75rem;
    div {
      width: 100%;
      text-align: center;
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  text-transform: uppercase;
`

const RightSide = styled(FlexBox)`
  width: 66%;
  padding: 6.375rem 9.167vw 8.625rem 6.597vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 2rem 6.039vw 3rem;
  }
`
