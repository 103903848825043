import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Expand, Mq, User } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useEffect, useState } from 'react'

import { Gallery } from './Gallery'
import { Slider } from './Slider'

export interface Props {
  buttonURL?: string
  category: string
  images?: ImageProps[]
  languageCode: string
  pax?: number
  services?: string[]
  size?: number
  thumbnails?: ImageProps[]
  title?: string
}

export const Room = memo(function Room({
  buttonURL,
  images,
  languageCode,
  pax,
  services,
  size,
  thumbnails,
  title,
}: Props) {
  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container row stretch wrap>
      <LeftSide>
        {thumbnails ? <Slider thumbnails={thumbnails} /> : null}
        {images?.length ? (
          <CTA
            dial={4}
            onClick={() => {
              if (!modalGalleryStatus) {
                setLocked(true)
                setModalGalleryStatus(true)
              } else {
                setLocked(false)
                setModalGalleryStatus(false)
              }
            }}
            row
          >
            <Expand />
            {useVocabularyData('expand', languageCode)}
          </CTA>
        ) : null}
      </LeftSide>
      <RightSide dial={4}>
        {title ? (
          <FadeInUp>
            <Wrap dial={4} row space="between">
              <Title>{title}</Title>
              {size || pax ? (
                <Info>
                  {size ? (
                    <Size dial={4} row>
                      <Mq />
                      {`${size} ${useVocabularyData('mq', languageCode)}`}
                    </Size>
                  ) : null}
                  {pax ? (
                    <Pax dial={4} row>
                      <User />
                      {`${pax} pax`}
                    </Pax>
                  ) : null}
                </Info>
              ) : null}
            </Wrap>
          </FadeInUp>
        ) : null}
        {services ? (
          <FadeInUp>
            <Services>
              {services.map((item, index) => (
                <Service key={index}>
                  {
                    // @ts-ignore
                    item.label
                  }
                </Service>
              ))}
            </Services>
          </FadeInUp>
        ) : null}
        {buttonURL ? (
          <FadeInUp>
            <ButtonBook
              label={useVocabularyData('book-now', languageCode)}
              target="_blank"
              URL={buttonURL}
            />
          </FadeInUp>
        ) : null}
      </RightSide>

      {images ? (
        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
            }}
            variant="R"
          />
          <Gallery images={images} isOpen={modalGalleryStatus} title={title} />
        </Modal>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 3.125rem;

  @media (max-width: 1023px) {
    margin-top: 5.625rem;
  }
`

const LeftSide = styled.div`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  padding-left: 8.333vw;

  > div {
    width: 100%;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 1.5rem;
    padding-left: 0;
  }
`

const Wrap = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  text-transform: uppercase;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
`

const Info = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  white-space: nowrap;

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin-right: 0.75rem;
  }

  @media (max-width: 1023px) {
    display: flex;
    width: 100%;
    margin-top: 0.75rem;
    div {
      &:nth-of-type(2) {
        margin-left: 1.125rem;
      }
    }
  }
`

const Size = styled(FlexBox)`
  margin-bottom: 0.75rem;

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
`

const Pax = styled(FlexBox)``

const Services = styled.div`
  margin-top: 2.5rem;
  padding-left: 1.875rem;
  position: relative;
  &:before {
    content: '';
    width: 0.625rem;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Service = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
`

const ButtonBook = styled(Button)`
  margin-top: 3.125rem;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const CTA = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 1.25rem;
  position: absolute;
  bottom: 0;
  left: 0;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  z-index: 2;
  &:hover {
    background: ${({ theme }) =>
      theme.colors.getHoverColor(theme.colors.variants.neutralLight3)};
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-right: 0.75rem;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
